import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ShopComponent} from "./shop.component";

export const routes: Routes = [
    {
        path: 'shop/t_shirts',
        component: ShopComponent
    },
    {
        path: 'shop/flags',
        component: ShopComponent
    },
    {
        path: 'shop/hoodies',
        component: ShopComponent
    },
    {
        path: 'shop/sweat_shirts',
        component: ShopComponent
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
    exports: [RouterModule]
})
export class ShopRoutingModule {
}
