<div id="topProductsComponent" class="py-16 md:py-28 px-10">
    <app-order-dialog [selectedProduct]="selectedProduct"></app-order-dialog>
    <div [@slideInFromBottom]="showAnimation ? 'visible' : 'hidden'">
        <app-text [class]="'text-center text-4xl md:text-5xl font-bold'" [value]="'Најпродавани производи'"></app-text>
    </div>
    <div class="grid md:grid-cols-3 gap-5 text-center">
        @for (product of products; track product) {
            <app-card class="pt-5" [product]="product" (click)="setSelectedProduct(product)"></app-card>
        }
    </div>
    <div class="mx-auto md:w-1/2 pt-10">
        <app-secondary-button
                (click)="goToUrl(router, '/shop')" class="flex w-2/3 md:w-80 mx-auto"
                [value]="'Види ги сите производи'"></app-secondary-button>
    </div>
</div>