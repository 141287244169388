import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShopComponent} from "./shop.component";
import {ButtonModule} from "../../components/button/button.module";
import {CardModule} from "../../components/card/card.module";
import {ShopService} from "./service/shop.service";
import {TextModule} from "../../components/text/text.module";
import {HttpClientModule} from "@angular/common/http";
import {ShopRoutingModule} from "./shop-routing.module";
import {OrderDialogModule} from "./components/order-dialog/order-dialog.module";
import {SecondaryButtonComponent} from "../../components/button/secondary/secondary.button.component";
import {LinkModule} from "../../components/link/link.module";
import {FooterModule} from "../../components/footer/footer.module";
import {ProductPageModule} from "./components/product-page/product-page.module";
import {PaginationModule} from "../../components/pagination/pagination.module";

@NgModule({
    declarations: [ShopComponent],
    imports: [
        CommonModule,
        ButtonModule,
        CardModule,
        TextModule,
        HttpClientModule,
        ShopRoutingModule,
        OrderDialogModule,
        SecondaryButtonComponent,
        LinkModule,
        FooterModule,
        ProductPageModule,
        PaginationModule
    ],
    providers: [ShopService]
})
export class ShopModule {
}
