import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardComponent} from "./card.component";
import {ButtonModule} from "../button/button.module";
import {ImageModule} from "../image/image.module";
import {TextModule} from "../text/text.module";
import {QuantitySelectorModule} from "../quantity-selector/quantity-selector.module";
import {PrimaryButtonComponent} from "../button/primary/primary.button.component";
import {SecondaryButtonComponent} from "../button/secondary/secondary.button.component";

@NgModule({
    declarations: [CardComponent],
    exports: [
        CardComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        ImageModule,
        TextModule,
        QuantitySelectorModule,
        PrimaryButtonComponent,
        SecondaryButtonComponent
    ]
})
export class CardModule {
}
