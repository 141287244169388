import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";

@Component({
    selector: 'app-quantity-selector',
    templateUrl: './quantity-selector.component.html',
    styleUrls: ['./quantity-selector.component.css']
})
export class QuantitySelectorComponent implements OnInit, OnChanges {

    @Input() _quantity: number = 1;

    @Input()
    control: UntypedFormControl = new UntypedFormControl(this._quantity);

    @Input() placeholder!: string;

    @Input()
    set quantity(value: number | undefined) {
        this._quantity = value || 1;
        this.updateControl();
    }

    @Output() quantityChange = new EventEmitter<number>();

    get quantity(): number {
        return this._quantity;
    }

    ngOnInit() {
        this.updateControl();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['_quantity'] && changes['_quantity'].currentValue !== this._quantity) {
            this._quantity = changes['_quantity'].currentValue || 1;
            this.updateControl();
            this.quantityChange.emit();
        }
    }

    private updateControl() {
        if (this.control) {
            this.control.setValue(Math.max(this.quantity, 1));
            this.quantityChange.emit(this.quantity);
        }
    }

    increment() {
        this.quantity++;
        this.quantityChange.emit(this.quantity);
        this.updateControl();
    }

    decrement() {
        this.quantity = Math.max(this.quantity - 1, 1);
        this.quantityChange.emit(this.quantity);
        this.updateControl();
    }
}