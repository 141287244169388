import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductCategoryListComponent} from "./product-category-list.component";
import {ImageModule} from "../image/image.module";
import {TextModule} from "../text/text.module";
import {LinkModule} from "../link/link.module";
import {RouterLink, RouterLinkActive} from "@angular/router";


@NgModule({
    declarations: [ProductCategoryListComponent],
    imports: [
        CommonModule,
        ImageModule,
        TextModule,
        LinkModule,
        RouterLink,
        RouterLinkActive
    ],
    exports: [
        ProductCategoryListComponent
    ]
})
export class ProductCategoryListModule {
}
