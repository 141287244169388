<div id="heroSection" class="relative"
     data-te-carousel-init data-te-ride="carousel">
    <!--Carousel indicators-->
    <div data-te-carousel-indicators
         class="absolute bottom-0 left-0 right-0 z-[2] mx-[15%] mb-14 flex list-none justify-center p-0">
        <button type="button"
                class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-label="Slide 1"
                data-te-target="#heroSection"
                data-te-slide-to="0"
                data-te-carousel-active
                aria-current="true"></button>
        @for (slide of slides; track slide; ) {
            @if ($index !== 0) {
                <button type="button"
                        class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                        data-te-target="#heroSection"
                        [attr.data-te-slide-to]="$index"
                        [attr.aria-label]="'Slide' + $index "></button>
            }
        }
    </div>
    <!--Carousel items-->
    <div class="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
        <div class="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
             data-te-carousel-active
             data-te-carousel-item
             style="backface-visibility: hidden">
            <div class="relative bg-cover bg-no-repeat bg-center h-screen"
                 [ngClass]="{'screen-image-mobile': isMobile, 'screen-image-desktop': !isMobile}"
                 [style.background-image]="'url(' + slides[0].bgUrl + ')'"
                 role="img"
                 [attr.aria-label]="slides[0].ariaLabel">
            </div>
            <app-primary-button
                    class="absolute text-center bottom-20 left-0 right-0 w-56 mx-auto z-10"
                    [value]="slides[0].buttonText"
                    (click)="slides[0].action()"></app-primary-button>
        </div>
        @for (slide of slides; track slide; ) {
            @if ($index !== 0) {
                <div class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                     data-te-carousel-item
                     style="backface-visibility: hidden">
                    <div class="relative bg-cover bg-center h-screen"
                         [ngClass]="{'screen-image-mobile': isMobile, 'screen-image-desktop': !isMobile}"
                         [style.background-image]="'url(' + slide.bgUrl + ')'"
                         role="img"
                         [attr.aria-label]="slide.ariaLabel">
                    </div>
                    <app-primary-button
                            class="absolute text-center bottom-20 left-0 right-0 w-56 mx-auto z-10"
                            [value]="slide.buttonText"
                            (click)="slide.action()"></app-primary-button>
                </div>
            }
        }

    </div>
    <!--Carousel controls - prev item-->
    <button class="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
            type="button"
            data-te-target="#heroSection"
            data-te-slide="prev">
    <span class="inline-block h-8 w-8">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="h-6 w-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"/>
      </svg>
    </span>
        <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Previous</span>
    </button>
    <!--Carousel controls - next item-->
    <button class="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
            type="button" data-te-target="#heroSection" data-te-slide="next">
        <span class="inline-block h-8 w-8">
      <svg xmlns="http://www.w3.org/2000/svg"
           fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"/>
      </svg>
    </span>
        <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Next</span>
    </button>
</div>