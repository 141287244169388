import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactUsComponent} from "./contact-us.component";
import {PrimaryButtonComponent} from "../../components/button/primary/primary.button.component";
import {TextModule} from "../../components/text/text.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TextInputModule} from "../../components/text-input/text-input.module";
import {ContactUsService} from "./service/contact-us.service";
import {ToastBarModule} from "../../components/toast-bar/toast-bar.module";
import {FooterModule} from "../../components/footer/footer.module";

@NgModule({
    declarations: [ContactUsComponent],
    imports: [
        CommonModule,
        PrimaryButtonComponent,
        TextModule,
        ReactiveFormsModule,
        TextInputModule,
        FormsModule,
        ToastBarModule,
        FooterModule,
    ],
    providers: [ContactUsService]
})
export class ContactUsModule {
}
