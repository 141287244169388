import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {initTE, Input as TwInput,} from "tw-elements";

@Component({
    selector: 'app-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.css']
})
export class TextInputComponent implements OnInit {

    @Input()
    control: UntypedFormControl = new UntypedFormControl;

    @Input()
    label: string = '';

    @Input()
    floatLabel: boolean = false;

    @Input()
    placeholder: string = '';

    @Input()
    type: string = 'text';

    ngOnInit(): void {
        initTE({TwInput});
    }

    getErrorMessages(): string[] {
        if ((this.control.touched || this.control.dirty) && this.control.errors) {
            return Object.keys(this.control.errors).map((error: string) => {
                switch (error) {
                    case 'required':
                        return 'Ова поле е задолжително';
                    case 'email':
                        return 'Невалиден е-маил формат!';
                    default:
                        return 'Неуспешна валидација!';
                }
            });
        }
        return [];
    }

    onFocusOutEvent(event: FocusEvent) {
        event.stopPropagation();
        if (this.control.valid) {
            // TODO
        }
    }

}
