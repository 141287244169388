<div class="flex">
    @if (auth.user$ | async; as user) {
        <app-image
                [src]="user.picture"
                class="w-8 lg:w-9  rounded-full shadow-lg z-[100] cursor-pointer"
                alt="user picture"
                (click)="goToUrl(router, environment.navigation.profile)"
        />
    } @else {
        <app-primary-button
                class="w-8 lg:w-9 button-on-hover z-[100]"
                [icon]="'/assets/icons/auth/profile.svg'"
                [tooltip]="'Profile'"
                (click)="auth.loginWithRedirect()"/>
    }
</div>