import {Component, OnInit} from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import {ProfileService} from "../profile/service/profile.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    constructor(
        public auth: AuthService,
        private readonly profileService: ProfileService) {
    }

    ngOnInit(): void {
        this.createCustomerFromLoggedUser();
    }

    createCustomerFromLoggedUser() {
        return this.auth.user$.subscribe(
            (user) => {
                this.profileService.getCustomerByEmail(user?.email).subscribe((customer) => {
                    if (customer == null) {
                        customer = {
                            name: user?.given_name,
                            surname: user?.family_name,
                            email: user?.email
                        }
                        this.profileService.updateCustomer(customer).subscribe();
                    }
                })
            })
    }

}
