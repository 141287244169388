import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Search} from "../../models/filters";
import {ShopService} from "../../modules/shop/service/shop.service";
import {getPageInfoDefaults, Page, PageInfo} from "../../models/paginator";
import {Product} from "../../models/product";
import {Carousel} from "tw-elements";
import {UntypedFormControl, Validators} from "@angular/forms";
import {debounceTime, distinctUntilChanged} from "rxjs";

@Component({
    selector: 'app-search-overview',
    templateUrl: './search-overview.component.html',
    styleUrl: './search-overview.component.css'
})
export class SearchOverviewComponent implements OnInit {

    @Input()
    showSearchOverview: boolean = false;

    @Output()
    showSearchOverviewChange = new EventEmitter<boolean>();

    products: Product[] = [];

    selectedProduct!: Product;

    pageInfo: PageInfo = getPageInfoDefaults();

    searchValue: string = '';

    searchDto: Search = {
        search: {
            value: this.searchValue,
            caseInsensitive: true,
            like: true,
        },
    };

    search: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

    constructor(
        private readonly shopService: ShopService) {
    }

    ngOnInit(): void {
        this.searchControl.valueChanges.pipe(
            debounceTime(200), // Add a debounce time to avoid making too many requests
            distinctUntilChanged() // Only trigger if the value changed
        ).subscribe(value => {
            this.searchDto.search.value = value;
            this.getProductList(this.searchDto);
        });
    }

    getProductList(
        search: Search
    ): void {
        this.shopService.getSearchedProducts(
            search,
            this.pageInfo.pageNumber,
            this.pageInfo.pageSize
        );

        this.shopService.productsPage$
            .pipe()
            .subscribe((productsPage: Page<Product>) => {
                this.products = productsPage.content;
                this.pageInfo.totalElements = productsPage.totalElements;
            });
    }

    get searchControl(): UntypedFormControl {
        return this.search as UntypedFormControl;
    }

    get noResultsMessage(): string {
        return `Нема резултати за: "${this.searchControl.value}". Обиди се со друг клучен збор.`;
    }

    setSelectedProduct(product: Product) {
        this.selectedProduct = product;
        this.closeSearchOverview();
        const orderDialogCarousel = new Carousel(document.getElementById("order-dialog-carousel"));
        orderDialogCarousel.to(0);
    }

    closeSearchOverview() {
        this.showSearchOverview = false;
        this.showSearchOverviewChange.emit(this.showSearchOverview);
    }

}