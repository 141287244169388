@if (floatLabel) {
    <form class="relative z-0" data-te-input-wrapper-init>
        <input [type]="type"
               [formControl]="control"
               id="floating_standard"
               class="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
               [placeholder]="placeholder"/>
        <label for="floating_standard"
               class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
            {{ label }}</label>
    </form>
} @else {
    <form class="relative" data-te-input-wrapper-init>
        @if (label) {
            <label for="formControlInputText"
                   class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary">
                {{ label }}
            </label>
        }
        <input [type]="type"
               [formControl]="control"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700"
               id="formControlInputText"
               [placeholder]="placeholder"
               (focusout)="onFocusOutEvent($event)"/>
        @for (error of getErrorMessages(); track error) {
            <div class="absolute top-full text-red-500 pt-0.5">{{ error }}</div>
        }
    </form>
}