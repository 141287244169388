import {Component} from '@angular/core';
import {ButtonComponent} from "../button.component";
import {IconTextModule} from "../../icon-text/icon-text.module";
import {SpinnerModule} from "../../spinner/spinner.module";

@Component({
    selector: 'app-primary-button',
    standalone: true,
    imports: [
        IconTextModule,
        SpinnerModule
    ],
    templateUrl: './primary.button.component.html',
    styleUrl: './primary.button.component.css'
})
export class PrimaryButtonComponent extends ButtonComponent {

}
