import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CheckoutComponent} from "./checkout.component";
import {TextModule} from "../../components/text/text.module";
import {ButtonModule} from "../../components/button/button.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TextInputModule} from "../../components/text-input/text-input.module";
import {ImageModule} from "../../components/image/image.module";
import {CheckoutService} from "./service/checkout.service";
import {ToastBarModule} from "../../components/toast-bar/toast-bar.module";
import {PrimaryButtonComponent} from "../../components/button/primary/primary.button.component";
import {ConfirmationModalModule} from "../../components/confirmation-modal/confirmation-modal.module";


@NgModule({
    declarations: [CheckoutComponent],
    imports: [
        CommonModule,
        TextModule,
        ButtonModule,
        FormsModule,
        TextInputModule,
        ReactiveFormsModule,
        ImageModule,
        ToastBarModule,
        PrimaryButtonComponent,
        ConfirmationModalModule
    ],
    providers: [CheckoutService]
})
export class CheckoutModule {
}
