import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {goToUrl} from "../../services/util/util";

@Component({
    selector: 'app-status-page',
    templateUrl: './status-page.component.html',
    styleUrl: './status-page.component.css'
})
export class StatusPageComponent implements OnInit {

    title: string = '';

    message: string = '';

    buttonLabel: string = 'Назад на почетната страница';

    buttonUrl: string = '/';

    titleStyle?: string;

    constructor(private route: ActivatedRoute, protected router: Router) {
    }

    ngOnInit(): void {
        this.route.data.subscribe(data => {
            this.title = data['title'];
            this.message = data['message'];
            this.buttonLabel = data['buttonLabel'];
            this.buttonUrl = data['buttonUrl'];
            this.titleStyle = data['style'];
        });
    }

    protected readonly goToUrl = goToUrl;
}