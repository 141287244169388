export interface PageInfo {
    pageNumber: number;
    pageSize: number;
    totalElements: number;
}

export function getPageInfoDefaults(): PageInfo {
    return {
        pageNumber: 0,
        pageSize: 10,
        totalElements: 0,
    };
}

export interface Page<T> {
    content: T[];
    empty: boolean;
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
    first: boolean;
    last: boolean;
    numberOfElements: number;
    pageable: Pageable;
    sort: Sort;
}

export interface Pageable {
    pageNumber: number;
    pageSize: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
    sort: Sort;
}

export interface Sort {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
}

export function getPageDefaults<T>(): Page<T> {
    return {
        content: [],
        empty: false,
        number: 0,
        size: 0,
        totalElements: 0,
        totalPages: 0,
        first: false,
        last: false,
        numberOfElements: 0,
        pageable: {
            pageNumber: 0,
            pageSize: 10,
            offset: 0,
            paged: true,
            unpaged: false,
            sort: {
                empty: true,
                sorted: false,
                unsorted: true
            }
        },
        sort: {
            empty: true,
            sorted: false,
            unsorted: true
        }
    };
}

export function getPageNumberAndSize(
    pageNumber?: number,
    pageSize?: number
): string {
    const pgNum =
        pageNumber !== undefined
            ? '?page=' + pageNumber
            : '?page=' + getPageInfoDefaults().pageNumber;
    const pgSize =
        pageSize !== undefined
            ? '&size=' + pageSize
            : '&size=' + getPageInfoDefaults().pageSize;

    return pgNum + pgSize;
}
