import {Component, OnInit} from '@angular/core';
import {Product} from "../../../../models/product";
import {ActivatedRoute, Router} from "@angular/router";
import {ShopService} from "../../service/shop.service";
import {switchMap} from "rxjs";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {initTE, Toast} from "tw-elements";
import {goToUrl} from "../../../../services/util/util";

@Component({
    selector: 'app-product-manage',
    templateUrl: './product-manage.component.html',
    styleUrl: './product-manage.component.css'
})
export class ProductManageComponent implements OnInit {

    product!: Product;

    productControl: UntypedFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl(''),
        category: new UntypedFormControl(''),
        designID: new UntypedFormControl(''),
        description: new UntypedFormControl(''),
        slug: new UntypedFormControl(''),
        keywords: new UntypedFormControl(''),
        subCategory: new UntypedFormControl(''),
        images: new UntypedFormControl(''),
        availableSizes: new UntypedFormControl(''),
        availableColors: new UntypedFormControl(''),
        originalPrice: new UntypedFormControl(''),
        price: new UntypedFormControl(''),
        created: new UntypedFormControl(''),
        discount: new UntypedFormControl('')
    });

    constructor(private route: ActivatedRoute,
                private shopService: ShopService,
                protected readonly router: Router) {
    }

    ngOnInit(): void {
        initTE({Toast});
        this.route.paramMap.pipe(
            switchMap(params => {
                const id = +params.get('id')!;
                return this.shopService.getProductById(id);
            })
        ).subscribe(product => {
            this.product = product;
            this.setProductValues(product);
        });
    }

    get nameControl(): UntypedFormControl {
        return this.productControl.get('name') as UntypedFormControl;
    }

    get categoryControl(): UntypedFormControl {
        return this.productControl.get('category') as UntypedFormControl;
    }

    get designIDControl(): UntypedFormControl {
        return this.productControl.get('designID') as UntypedFormControl;
    }

    get descriptionControl(): UntypedFormControl {
        return this.productControl.get('description') as UntypedFormControl;
    }

    get slugControl(): UntypedFormControl {
        return this.productControl.get('slug') as UntypedFormControl;
    }

    get keywordsControl(): UntypedFormControl {
        return this.productControl.get('keywords') as UntypedFormControl;
    }

    get subCategoryControl(): UntypedFormControl {
        return this.productControl.get('subCategory') as UntypedFormControl;
    }

    get imagesControl(): UntypedFormControl {
        return this.productControl.get('images') as UntypedFormControl;
    }

    get availableSizesControl(): UntypedFormControl {
        return this.productControl.get('availableSizes') as UntypedFormControl;
    }

    get availableColorsControl(): UntypedFormControl {
        return this.productControl.get('availableColors') as UntypedFormControl;
    }

    get originalPriceControl(): UntypedFormControl {
        return this.productControl.get('originalPrice') as UntypedFormControl;
    }

    get pricePriceControl(): UntypedFormControl {
        return this.productControl.get('price') as UntypedFormControl;
    }

    get createdControl(): UntypedFormControl {
        return this.productControl.get('created') as UntypedFormControl;
    }

    get discountControl(): UntypedFormControl {
        return this.productControl.get('discount') as UntypedFormControl;
    }

    setProductValues(product: Product) {
        this.nameControl.setValue(product.name);
        this.categoryControl.setValue(product.category);
        this.designIDControl.setValue(product.designID);
        this.descriptionControl.setValue(product.description);
        this.slugControl.setValue(product.slug);
        this.keywordsControl.setValue(product.keywords);
        this.subCategoryControl.setValue(product.subCategory);
        this.imagesControl.setValue(product.images);
        this.availableSizesControl.setValue(product.availableSizes);
        this.availableColorsControl.setValue(product.availableColors);
        this.originalPriceControl.setValue(product.originalPrice);
        this.pricePriceControl.setValue(product.price);
        this.createdControl.setValue(product.created);
        this.discountControl.setValue(product.discount);
    }

    updateProduct() {
        const images = typeof this.imagesControl.value === 'string'
            ? this.imagesControl.value.split(',').map(img => img.trim())
            : Array.isArray(this.imagesControl.value)
                ? this.imagesControl.value
                : [];

        const product: Product = {
            id: this.product?.id,
            name: this.nameControl.value || this.product?.name,
            category: this.categoryControl.value || this.product?.category,
            designID: this.designIDControl.value || this.product?.designID,
            description: this.descriptionControl.value || this.product?.description,
            slug: this.slugControl.value || this.product?.slug,
            keywords: this.formatKeywords(this.keywordsControl.value) || this.product?.keywords,
            subCategory: this.subCategoryControl.value || this.product?.subCategory,
            images: images,
            availableSizes: this.availableSizesControl.value || this.product?.availableSizes,
            availableColors: this.availableColorsControl.value || this.product?.availableColors,
            originalPrice: this.originalPriceControl.value || this.product?.originalPrice,
            price: this.pricePriceControl.value || this.product?.price,
            created: this.createdControl.value || this.product?.created,
            discount: this.discountControl.value || this.product?.discount
        };

        this.shopService.updateProduct(product).subscribe({
            next: () => alert("Успешно ажурирање на податоци"),
            error: () => alert("Грешка при ажурирање на податоци"),
        });
    }

    deleteProduct() {
        this.shopService.deleteProduct(this.product.id).subscribe({
            next: () => {
                alert("Успешно избришан продукт");
                goToUrl(this.router, "/shop");
            },
            error: () => alert("Грешка при бришење на продукт"),
        });
    }

    private formatKeywords(value: string | undefined): string[] | undefined {
        if (typeof value === 'string') {
            return value
                .split(',')
                .map(keyword => keyword.trim())
                .filter(keyword => keyword.length > 0);
        } else {
            return undefined;
        }
    }

    protected readonly document = document;
}