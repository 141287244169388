import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DropdownOption} from "../../../../components/dropdown/model/dropdown-option";
import {Product} from "../../../../models/product";
import {OrderItem} from "../../../../models/order-item";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Carousel, initTE, Modal, Sidenav, Toast} from "tw-elements";
import {Router} from "@angular/router";
import {CartService} from "../../../cart/service/cart.service";

@Component({
    selector: 'app-order-dialog',
    templateUrl: './order-dialog.component.html',
    styleUrls: ['./order-dialog.component.css']
})

export class OrderDialogComponent implements OnInit, OnDestroy {

    orderItemControl: UntypedFormGroup = new UntypedFormGroup({
        quantity: new UntypedFormControl('', [Validators.required]),
        size: new UntypedFormControl('', [Validators.required]),
        color: new UntypedFormControl('', [Validators.required,])
    });

    @Input()
    selectedProduct!: Product | null;

    toastMessage: string = '';

    sizeOptions: DropdownOption[] = [
        {
            label: 'S',
            value: 'S'
        },
        {
            label: 'M',
            value: 'M'
        },
        {
            label: 'L',
            value: 'L'
        },
        {
            label: 'XL',
            value: 'XL'
        },
        {
            label: 'XXL',
            value: 'XXL'
        },
        {
            label: 'XXXL',
            value: 'XXXL'
        }
    ]

    jerseySizeOptions: DropdownOption[] = [
        {
            label: '116-122 (3-4 год)',
            value: '116-122 (3-4 год)'
        },
        {
            label: '128-134 (5-6 год)',
            value: '128-134 (5-6 год)'
        },
        {
            label: '140-152 (7-8 год)',
            value: '140-152 (7-8 год)'
        },
        {
            label: '152 (9-10 год)',
            value: '152 (9-10 год)'
        },
        {
            label: '164 (10-11 год)',
            value: '164 (10-11 год)'
        },
        {
            label: '170 (11-12 год)',
            value: '170 (11-12 год)'
        },
        {
            label: 'S',
            value: 'S'
        },
        {
            label: 'M',
            value: 'M'
        },
        {
            label: 'L',
            value: 'L'
        },
        {
            label: 'XL',
            value: 'XL'
        },
        {
            label: 'XXL',
            value: 'XXL'
        },
    ]

    colorOptions: DropdownOption[] = [
        {
            label: 'Црна',
            value: 'crna'
        },
        {
            label: 'Бела',
            value: 'bela'
        },
    ]

    constructor(private readonly router: Router,
                private cartService: CartService) {
    }

    ngOnInit(): void {
        initTE({Modal, Carousel});
        window.addEventListener('popstate', this.handlePopState);
    }

    ngOnDestroy(): void {
        window.removeEventListener('popstate', this.handlePopState);
    }

    handlePopState = () => {
        const modalElement = document.getElementById("orderDialog");
        if (modalElement) {
            const modalInstance = Modal.getInstance(modalElement);
            if (modalInstance) {
                modalInstance.hide();
            }
        }
    };

    get quantityControl(): UntypedFormControl {
        return this.orderItemControl.get('quantity') as UntypedFormControl;
    }

    get sizeControl(): UntypedFormControl {
        return this.orderItemControl.get('size') as UntypedFormControl;
    }

    get colorControl(): UntypedFormControl {
        return this.orderItemControl.get('color') as UntypedFormControl;
    }

    onSizeChange(selectedValue: string): void {
        this.sizeControl.setValue(selectedValue);
    }

    onColorChange(selectedValue: string): void {
        this.colorControl.setValue(selectedValue);
    }

    addToCart() {
        if (this.isOrderItemValid()) {
            this.processOrderItem();
            const cartOverview = new Sidenav(document.getElementById("cart-overview"));
            cartOverview.show();
        } else {
            this.openToast('Пополни ги полињата за нарачка');
        }
    }

    buyNow() {
        if (this.isOrderItemValid()) {
            this.processOrderItem();
            this.router.navigateByUrl('/checkout');
        } else {
            this.openToast('Пополни ги полињата за нарачка');
        }
    }

    private isOrderItemValid(): boolean {
        return this.orderItemControl.status === "VALID" ||
            this.selectedProduct?.category === "FLAG" ||
            this.selectedProduct?.category === "BOTTLE" ||
            this.selectedProduct?.category === "OTHER" ||
            this.selectedProduct?.category === "JERSEY";
    }

    private processOrderItem(): OrderItem {
        const orderItem = this.createOrderItem();
        this.cartService.updateCartItems([...this.cartService.retrieveItemsFromLocalStorage(), orderItem]);
        this.updateLocalStorage(orderItem);
        return orderItem;
    }

    private createOrderItem(): OrderItem {
        return {
            product: this.selectedProduct,
            quantity: this.quantityControl.value,
            size: this.sizeControl.value,
            color: this.colorControl.value,
        };
    }

    private updateLocalStorage(orderItem: OrderItem): void {
        const existingItems = this.getExistingItems();
        existingItems.push(orderItem);
        localStorage.setItem('cartItems', JSON.stringify(existingItems));
    }

    private getExistingItems(): OrderItem[] {
        const existingItemsJson = localStorage.getItem('cartItems');
        return existingItemsJson ? JSON.parse(existingItemsJson) : [];
    }

    setProductCategory(category: string | undefined) {
        switch (category) {
            case 'T_SHIRT':
                return "Маица - "
            case 'HOODIE':
                return "Дуксер - "
            case 'SWEAT_SHIRT':
                return "Блузон - "
            case 'JERSEY':
                return "Дрес - "
            case 'FLAG':
                return "Знаме - "
            default :
                return ""
        }
    }

    openToast(message: string) {
        const toast = new Toast(document.getElementById("toast"));
        this.toastMessage = message;
        toast.show();
    }

}