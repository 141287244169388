import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {Customer} from "../../../models/customer";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(
        private readonly httpClient: HttpClient,
    ) {
    }

    updateCustomer(customer: Customer): Observable<Customer> {
        if (customer.email === undefined) {
            return of(customer);
        }

        const url: string = environment.domain.backend + environment.api.customers;

        return this.httpClient.post<Customer>(url, customer);
    }

    getCustomerByEmail(email: string | undefined): Observable<Customer> {
        const url: string = environment.domain.backend + environment.api.customers + "/email/" + email;

        return this.httpClient.get<Customer>(url);
    }
}