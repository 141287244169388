import {Component, Input, OnInit} from '@angular/core';
import {initTE, Ripple, Tooltip} from "tw-elements";


@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {


    @Input()
    value!: string;

    @Input()
    styleType: 'none' | 'primary' | 'secondary' = 'none';

    @Input()
    disabled: boolean = false;

    @Input() isActive: boolean = false

    @Input()
    icon!: string;

    @Input()
    tooltip!: string;

    @Input()
    isLoading: boolean = false;

    ngOnInit(): void {
        initTE({Ripple, Tooltip});
    }

    getAriaLabel(): string {
        if (this.icon) {
            return 'Button with icon';
        }
        if (this.isLoading) {
            return 'Loading';
        }
        return this.value || 'Button';
    }
}
