import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrderDialogComponent} from "./order-dialog.component";
import {ImageModule} from "../../../../components/image/image.module";
import {TextModule} from "../../../../components/text/text.module";
import {ButtonModule} from "../../../../components/button/button.module";
import {DropdownMenuModule} from "../../../../components/dropdown/dropdown-menu.module";
import {QuantitySelectorModule} from "../../../../components/quantity-selector/quantity-selector.module";
import {ReactiveFormsModule} from "@angular/forms";
import {ToastBarModule} from "../../../../components/toast-bar/toast-bar.module";
import {SecondaryButtonComponent} from "../../../../components/button/secondary/secondary.button.component";
import {PrimaryButtonComponent} from "../../../../components/button/primary/primary.button.component";

@NgModule({
    declarations: [OrderDialogComponent],
    exports: [
        OrderDialogComponent
    ],
    imports: [
        CommonModule,
        ImageModule,
        TextModule,
        ButtonModule,
        DropdownMenuModule,
        QuantitySelectorModule,
        ReactiveFormsModule,
        ToastBarModule,
        SecondaryButtonComponent,
        PrimaryButtonComponent
    ]
})
export class OrderDialogModule {
}
