import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {ContactForm} from "../../../models/contact-form";

@Injectable({
    providedIn: 'root'
})
export class ContactUsService {

    constructor(private readonly httpClient: HttpClient) {
    }

    sendMessage(message: ContactForm): Observable<HttpResponse<void>> {
        const url: string = environment.domain.backend + environment.api.notifications + '/message';

        return this.httpClient.post<void>(url, message, {observe: 'response'});
    }

}