import {Injectable} from '@angular/core';
import {OrderItem} from "../../../models/order-item";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class CartService {

    cartItems$ = new BehaviorSubject<OrderItem[]>([]);

    constructor() {
    }

    updateCartItems(items: OrderItem[]) {
        this.cartItems$.next(items);
    }

    retrieveItemsFromLocalStorage(): OrderItem[] {
        const cartItems = localStorage.getItem('cartItems');
        return cartItems ? JSON.parse(cartItems) : [];
    }

    removeItemFromLocalStorage(index: number): OrderItem[] {
        const storedItems = this.retrieveItemsFromLocalStorage();
        if (index >= 0 && index < storedItems.length) {
            storedItems.splice(index, 1);
            localStorage.setItem('cartItems', JSON.stringify(storedItems));
        }
        return storedItems;
    }

    clearLocalStorage(): void {
        localStorage.removeItem('cartItems');
    }
}