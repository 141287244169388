import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconTextComponent} from "./icon-text.component";
import {ImageModule} from "../image/image.module";
import {TextModule} from "../text/text.module";
import {SvgIconComponent} from "./svg-icon/svg-icon.component";
import {SpinnerModule} from "../spinner/spinner.module";


@NgModule({
    declarations: [IconTextComponent, SvgIconComponent],
    exports: [
        IconTextComponent,
        SvgIconComponent
    ],
    imports: [
        CommonModule,
        ImageModule,
        TextModule,
        SpinnerModule
    ]
})
export class IconTextModule {
}
