import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Product} from "../../../../models/product";
import {Carousel} from "tw-elements";
import {ShopService} from "../../service/shop.service";

@Component({
    selector: 'app-related-products',
    templateUrl: './related-products.component.html',
    styleUrl: './related-products.component.css'
})
export class RelatedProductsComponent implements OnInit, OnChanges {

    @Input()
    product?: Product;

    relatedProducts: Product[] = [];

    selectedProduct!: Product;

    constructor(
        private shopService: ShopService
    ) {
    }

    ngOnInit(): void {
        this.getProductsByCategory();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['product'] && !changes['product'].firstChange) {
            this.getProductsByCategory();
        }
    }

    getProductsByCategory(): void {
        if (this.product?.category)
            this.shopService.getProductsByCategory(this.product?.category).subscribe((products) => {
                products = this.shuffleArray(products);
                this.relatedProducts = products.filter(p => p.id !== this.product!.id).slice(0, 4);
            })
    }

    setSelectedProduct(product: Product) {
        this.selectedProduct = product;
        const orderDialogCarousel = new Carousel(document.getElementById("order-dialog-carousel"));
        orderDialogCarousel.to(0);
    }

    private shuffleArray(array: Product[]): Product[] {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

}