import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ContactForm} from "../../models/contact-form";
import {initTE, Toast} from "tw-elements";
import {ContactUsService} from "./service/contact-us.service";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrl: './contact-us.component.css'
})
export class ContactUsComponent implements OnInit {

    contactForm: UntypedFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required]),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        subject: new UntypedFormControl('', [Validators.required]),
        message: new UntypedFormControl('', [Validators.required,]),
    });

    toastMessage: string = '';

    isLoading: boolean = false;

    constructor(private readonly contactUsService: ContactUsService,
                private titleService: Title,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        initTE({Input});
        this.route.data.subscribe(data => {
            if (data['title']) {
                this.titleService.setTitle(data['title']);
            }
        });
    }

    get nameControl(): UntypedFormControl {
        return this.contactForm.get('name') as UntypedFormControl;
    }

    get emailControl(): UntypedFormControl {
        return this.contactForm.get('email') as UntypedFormControl;
    }

    get subjectControl(): UntypedFormControl {
        return this.contactForm.get('subject') as UntypedFormControl;
    }

    get messageControl(): UntypedFormControl {
        return this.contactForm.get('message') as UntypedFormControl;
    }


    sendMessage(): void {
        if (this.contactForm.status === "VALID") {
            this.isLoading = true;
            const contactForm: ContactForm = {
                name: this.nameControl.value,
                email: this.emailControl.value,
                subject: this.subjectControl.value,
                message: this.messageControl.value,
            }

            this.contactUsService.sendMessage(contactForm).subscribe({
                next: () => this.openToast("Успешно пратена порака."),
                error: () => this.openToast("Грешка при праќање на пораката."),
                complete: () => this.isLoading = false
            });

        } else {
            if (this.emailControl.hasError('email')) {
                this.openToast("Невалидна е-маил адреса")
            } else {
                this.openToast("Пополнете ја формата")
            }
        }
    }

    openToast(message: string) {
        const toast = new Toast(document.getElementById("toast"));
        this.toastMessage = message;
        toast.show();
    }
}