import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrl: './pagination.component.css'
})
export class PaginationComponent {
    @Input() pageNumber: number = 0;
    @Input() totalPages: number = 0;

    @Output() pageChange = new EventEmitter<number>();

    nextPage(): void {
        if (this.pageNumber < this.totalPages - 1) {
            this.pageNumber++;
            this.scrollToTop();
            this.pageChange.emit(this.pageNumber);
        }
    }

    previousPage(): void {
        if (this.pageNumber > 0) {
            this.pageNumber--;
            this.scrollToTop();
            this.pageChange.emit(this.pageNumber);
        }
    }

    onPageChange(page: number): void {
        this.pageNumber = page;
        this.scrollToTop();
        this.pageChange.emit(this.pageNumber);
    }

    getDisplayedPageNumbers(): number[] {
        const totalPagesToShow = 3;
        let startPage = Math.max(0, this.pageNumber - Math.floor(totalPagesToShow / 2));
        const endPage = Math.min(this.totalPages, startPage + totalPagesToShow);

        if (endPage - startPage < totalPagesToShow) {
            startPage = Math.max(0, endPage - totalPagesToShow);
        }

        const pageNumbers: number[] = [];
        for (let i = startPage; i < endPage; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    }

    scrollToTop(): void {
        window.scrollTo({top: 0, behavior: 'instant'});
    }

}