import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from "./footer.component";
import {TextModule} from "../text/text.module";
import {LinkModule} from "../link/link.module";
import {ButtonModule} from "../button/button.module";
import {PrimaryButtonComponent} from "../button/primary/primary.button.component";


@NgModule({
    declarations: [FooterComponent],
    imports: [
        CommonModule,
        TextModule,
        LinkModule,
        ButtonModule,
        PrimaryButtonComponent
    ],
    exports: [
        FooterComponent
    ]
})
export class FooterModule {
}
