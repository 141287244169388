import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {Playlist} from "../../../models/playlist";

@Injectable({
    providedIn: 'root'
})
export class MusicPlayerService {

    constructor(
        private readonly httpClient: HttpClient) {
    }

    getYoutubePlaylist(playlistId: string): Observable<Playlist> {
        const url: string =
            environment.domain.backend +
            environment.api.spotify +
            "/youtube-playlist?playlistId=" + playlistId;

        return this.httpClient.get<Playlist>(url);
    }
}