import {Component, Input} from '@angular/core';
import {ColumnMode} from "@swimlane/ngx-datatable";

export interface TableColumn {
    prop: string;
    name: string;
    width?: number;
    sortable?: boolean;
    filterable?: boolean;
}

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrl: './table.component.css'
})
export class TableComponent<T> {

    protected readonly ColumnMode = ColumnMode;

    @Input() rows: T[] = [];

    @Input() columns: TableColumn[] = [];
}