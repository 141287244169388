import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QuantitySelectorComponent} from "./quantity-selector.component";
import {ButtonModule} from "../button/button.module";
import {ReactiveFormsModule} from "@angular/forms";


@NgModule({
    declarations: [QuantitySelectorComponent],
    exports: [
        QuantitySelectorComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        ReactiveFormsModule,
    ]
})
export class QuantitySelectorModule {
}
