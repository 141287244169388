
<ngx-datatable
        class="shadow-lg rounded-lg p-5 text-sm lg:text-base material"
        [rows]="rows"
        [columns]="columns"
        [columnMode]="ColumnMode.force"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="40"
        [scrollbarH]="true">
</ngx-datatable>