import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {OrderItem} from "../../models/order-item";
import {Product} from "../../models/product";
import {CartService} from "../cart/service/cart.service";
import {Router} from "@angular/router";
import {CheckoutService} from "./service/checkout.service";
import {OrderCreationRequest} from "../../models/order";
import {AuthService} from "@auth0/auth0-angular";
import {initTE, Modal, Toast} from "tw-elements";
import {ProfileService} from "../profile/service/profile.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {goToUrl} from "../../services/util/util";

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.css'],
    animations: [
        trigger('slideDown', [
            transition(':enter', [
                style({transform: 'translateY(-50%)', opacity: 0}),
                animate('0.7s')
            ])
        ])]
})
export class CheckoutComponent implements OnInit {

    order: UntypedFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required]),
        surname: new UntypedFormControl('', [Validators.required]),
        phoneNumber: new UntypedFormControl('', [Validators.required]),
        address: new UntypedFormControl('', [Validators.required]),
        city: new UntypedFormControl('', [Validators.required]),
        email: new UntypedFormControl('', [Validators.email]),
    });

    orderPrice: number = 0

    deliveryExpenses: number = 150;

    totalPrice: number = 0;

    orderItems: OrderItem[] = [];

    product?: Product;

    toastMessage: string = '';

    isLoading: boolean = false;

    constructor(
        private readonly cartService: CartService,
        private readonly checkoutService: CheckoutService,
        protected readonly router: Router,
        public auth: AuthService,
        private readonly profileService: ProfileService) {
    }

    ngOnInit(): void {
        initTE({Toast});
        this.setCustomerValues();
        this.orderItems = this.cartService.retrieveItemsFromLocalStorage();
        this.calculateTotal();
    }

    get nameControl(): UntypedFormControl {
        return this.order.get('name') as UntypedFormControl;
    }

    get surnameControl(): UntypedFormControl {
        return this.order.get('surname') as UntypedFormControl;
    }

    get phoneNumberControl(): UntypedFormControl {
        return this.order.get('phoneNumber') as UntypedFormControl;
    }

    get addressControl(): UntypedFormControl {
        return this.order.get('address') as UntypedFormControl;
    }


    get cityControl(): UntypedFormControl {
        return this.order.get('city') as UntypedFormControl;
    }

    get emailControl(): UntypedFormControl {
        return this.order.get('email') as UntypedFormControl;
    }

    setCustomerValues() {
        return this.auth.user$.subscribe(
            (user) => {
                this.profileService.getCustomerByEmail(user?.email).subscribe((customer) => {
                    this.nameControl.setValue(customer.name)
                    this.surnameControl.setValue(customer.surname)
                    this.emailControl.setValue(customer.email)
                    this.addressControl.setValue(customer.address)
                    this.cityControl.setValue(customer.city)
                    this.phoneNumberControl.setValue(customer.phoneNumber)
                })
            })
    }

    setOrderItemImage(orderItem: OrderItem) {
        switch (orderItem.color) {
            case 'bela':
                return 'assets/images/' + orderItem?.product?.category?.toLowerCase()?.replace('_', '-') + '/' + (orderItem?.product?.images?.[0].replace("black", "white") ?? '') + '.webp'
            default:
                return 'assets/images/' + orderItem?.product?.category?.toLowerCase()?.replace('_', '-') + '/' + (orderItem?.product?.images?.[0] ?? '') + '.webp'
        }
    }

    setProductCategory(category: string | undefined) {
        switch (category) {
            case 'T_SHIRT':
                return "Маица - "
            case 'HOODIE':
                return "Дуксер - "
            case 'SWEAT_SHIRT':
                return "Блузон - "
            case 'JERSEY':
                return "Дрес - "
            case 'FLAG':
                return "Знаме - "
            default :
                return ""
        }
    }

    calculateTotal() {
        this.totalPrice = 0;
        this.orderPrice = 0;

        for (const orderItem of this.orderItems) {
            if (orderItem.product && orderItem.product.price && orderItem.quantity) {
                this.orderPrice += orderItem.product.price * orderItem.quantity;
            }
        }
        if (this.orderPrice < 1400) {
            this.totalPrice += this.deliveryExpenses;
        }

        this.totalPrice += this.orderPrice;
    }

    submitOrder(): void {
        this.isLoading = true;
        const orderCreationRequest: OrderCreationRequest = {
            customer: {
                name: this.nameControl.value,
                surname: this.surnameControl.value,
                phoneNumber: this.phoneNumberControl.value,
                address: this.addressControl.value,
                city: this.cityControl.value,
                email: this.emailControl.value
            },
            orderItems: this.orderItems,
            totalPrice: this.totalPrice
        };

        this.checkoutService.submitOrder(orderCreationRequest)
            .subscribe({
                next: () => {
                    this.openToast("Вашата нарачка е пратена");
                    this.cartService.clearLocalStorage();
                    setTimeout(() => {
                        this.goToUrl(this.router, '/uspesna-naracka');
                    }, 2000);
                },
                error: () => {
                    this.openToast("Грешка при праќање на нарачка");
                    this.isLoading = false;
                },
                complete: () => this.isLoading = false
            });
    }

    openToast(message: string) {
        const toast = new Toast(document.getElementById("toast"));
        this.toastMessage = message;
        toast.show();
    }

    openConfirmationModal(): void {
        if (this.order.status === 'VALID' && this.orderItems.length > 0) {
            const confirmationModal = new Modal(document.getElementById("confirmationModal"));
            confirmationModal.show();
        } else {
            if (this.order.status !== 'VALID') {
                this.openToast('Пополнете ја формата');
            } else {
                this.openToast('Додади производи во кошничката');
            }
        }
    }

    protected readonly goToUrl = goToUrl;
}