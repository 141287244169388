<div id="iconListText"
     class="bg-gray-100 pt-20 pb-24 px-8 lg:px-20">
    <div class="xl:grid xl:grid-cols-4 space-x-5 text-black"
         [@slideFromLeft]="showAnimation ? 'visible' : 'hidden'">
        @for (link of links; track link) {
            <app-icon-text
                    [path]="link.route"
                    [title]="link.title ?? ''"
                    [text]="link.text">
            </app-icon-text>
        }
    </div>
</div>