import {Component, Input, OnInit} from '@angular/core';
import {initTE, Toast} from 'tw-elements';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-toast',
    templateUrl: './toast-bar.component.html',
    styleUrls: ['./toast-bar.component.css'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({transform: 'translateY(100%)'}),
                animate('200ms ease-in'),
            ]),
            transition(':leave', [
                animate('200ms ease-out', style({transform: 'translateY(100%)'})),
            ]),
        ]),
    ],
})
export class ToastBarComponent implements OnInit {

    @Input() message: string = '';

    ngOnInit(): void {
        initTE({Toast});
    }

}
