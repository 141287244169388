<div class="h-fit p-16 pt-24 lg:pt-40">
    <app-order-dialog [selectedProduct]="selectedProduct"></app-order-dialog>
    <div @slideDown>
        <app-text class="text-center text-4xl lg:text-7xl font-black"
                  [value]="pageTitle.toUpperCase()"></app-text>
    </div>
    <div class="pt-3 md:mt-10 lg:grid hidden md:grid-cols-8 text-center lg:w-4/5 mx-auto font-medium md:space-x-5 space-y-2 md:space-y-0">
        @for (link of links; track link; let i = $index) {
            <app-secondary-button
                    @slideDown [value]="link.text"
                    (click)="goToUrl(router, link.route)"></app-secondary-button>
        }
    </div>
    <div class="pt-10 lg:w-2/3 mx-auto grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
         [ngClass]="products.length === 0 ? 'h-screen' : ''">
        @for (product of products; track product) {
            <div>
                <app-card @slideDown [product]="product" (click)="setSelectedProduct(product)"></app-card>
            </div>
        }
    </div>
    @if (totalPages !== 0) {
        <app-pagination
                [pageNumber]="pageNumber"
                [totalPages]="totalPages"
                (pageChange)="onPageChange($event)">
        </app-pagination>
    }
</div>
<app-footer></app-footer>