import {NgModule} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {ProfileComponent} from "./profile.component";
import {TextModule} from "../../components/text/text.module";
import {TextInputModule} from "../../components/text-input/text-input.module";
import {SecondaryButtonComponent} from "../../components/button/secondary/secondary.button.component";
import {PrimaryButtonComponent} from "../../components/button/primary/primary.button.component";
import {ProfileService} from "./service/profile.service";
import {ToastBarModule} from "../../components/toast-bar/toast-bar.module";
import {FooterModule} from "../../components/footer/footer.module";


@NgModule({
    declarations: [ProfileComponent],
    imports: [
        AsyncPipe,
        TextModule,
        TextInputModule,
        SecondaryButtonComponent,
        PrimaryButtonComponent,
        ToastBarModule,
        FooterModule,
    ],
    providers: [ProfileService]
})
export class ProfileModule {
}
