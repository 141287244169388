import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmationModalComponent} from "./confirmation-modal.component";
import {PrimaryButtonComponent} from "../button/primary/primary.button.component";
import {SecondaryButtonComponent} from "../button/secondary/secondary.button.component";


@NgModule({
    declarations: [ConfirmationModalComponent],
    exports: [
        ConfirmationModalComponent
    ],
    imports: [
        CommonModule,
        PrimaryButtonComponent,
        SecondaryButtonComponent
    ]
})
export class ConfirmationModalModule {
}
