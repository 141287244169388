import {BehaviorSubject, tap} from "rxjs";
import {Page} from "../../models/paginator";
import {HttpClient} from "@angular/common/http";
import {Search} from "../../models/filters";
import {Router} from "@angular/router";

export function getEntityPage<T>(
    pageSubject$: BehaviorSubject<Page<T>>,
    httpClient: HttpClient,
    url: string
): void {
    httpClient
        .get<Page<T>>(url)
        .pipe(tap((entityPage: Page<T>) => pageSubject$.next(entityPage)))
        .subscribe();
}

export function getSearchedEntity<T>(
    search: Search,
    pageSubject$: BehaviorSubject<Page<T>>,
    httpClient: HttpClient,
    url: string
): void {
    httpClient
        .post<Page<T>>(url, search.search)
        .pipe(tap((entityPage: Page<T>) => pageSubject$.next(entityPage)))
        .subscribe();
}

export function goToUrl(router: Router, url: string): void {
    router.navigateByUrl(url).then(() => {
        window.scrollTo(0, 0);
    })
}