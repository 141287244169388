import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ButtonComponent} from "./button.component";
import {IconTextModule} from "../icon-text/icon-text.module";


@NgModule({
    declarations: [ButtonComponent],
    imports: [CommonModule, NgOptimizedImage, IconTextModule],
    providers: [],
    exports: [ButtonComponent]
})
export class ButtonModule {
}
