<div id="emailFormComponent" class="bg-gray-300 py-20 px-10">
    <div [@slideInFromBottom]="showAnimation ? 'visible' : 'hidden'">
        <app-text [class]="'text-center text-4xl md:text-5xl font-bold'"
                  [value]="'ПРИДРУЖИ НИ СЕ !'"></app-text>
    </div>
    <app-text [class]="'py-8 text-center text-lg md:text-2xl'"
              value="Дознај прв за сите попусти и промоции"></app-text>
    <div class="md:flex mx-auto w-4/5 md:2/3 md:w-full justify-center items-center space-y-6 md:space-y-0 gap-5">
        <div class="w-full lg:w-1/3">
            <app-text-input
                    [placeholder]="'Внеси е-маил адреса'"
                    [control]="emailFormControl"
                    [type]="'email'"></app-text-input>
        </div>
        <form class="w-full md:w-44">
            <app-primary-button
                    [isLoading]="isLoading"
                    [value]="'Придружи се'"
                    (click)="submitEmail()"></app-primary-button>
<!-- rework it to use toastComponent-->
            @if (showInfoMessage) {
                <div class="z-40 fixed bottom-5 right-0 left-0 pointer-events-auto mx-auto w-96 max-w-full rounded-lg bg-white bg-clip-padding text-sm shadow-lg shadow-black/5  dark:bg-neutral-600">
                    <div class="flex items-center justify-between rounded-lg border-b-2 border-neutral-100 border-opacity-100 bg-white bg-clip-padding px-4 pb-2 pt-2.5 dark:border-opacity-50 dark:bg-neutral-600">
                        <p class="font-bold text-neutral-500 dark:text-neutral-200">
                            {{ infoMessage }}
                        </p>
                        <div class="flex items-center">
                            <button type="button"
                                    class="ml-2 box-content rounded-none border-none opacity-80 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                    aria-label="Close"
                                    (click)="closeInfoMessage()"
                            >
        <span class="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
          <svg xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               stroke-width="1.5"
               stroke="currentColor"
               class="h-6 w-6">
            <path stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </span>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </form>
    </div>
</div>