import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Customer} from "../../../models/customer";

@Injectable({
    providedIn: 'root'
})
export class EmailFormService {

    constructor(
        private readonly httpClient: HttpClient,
    ) {
    }

    //TODO @rev rework - possibly obsolete;
    createCustomerWithEmail(email: string): Observable<Customer> {
        const url: string =
            environment.domain.backend + environment.api.customers;

        const request: Customer = {
            email: email
        }

        return this.httpClient.post<Customer>(url, request);
    }

}