import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeroSectionComponent} from "./hero-section.component";
import {ImageModule} from "../image/image.module";
import {ButtonModule} from "../button/button.module";
import {PrimaryButtonComponent} from "../button/primary/primary.button.component";



@NgModule({
  declarations: [HeroSectionComponent],
    imports: [
        CommonModule,
        ImageModule,
        ButtonModule,
        PrimaryButtonComponent,
    ],
  exports: [
    HeroSectionComponent
  ]
})
export class HeroSectionModule { }
