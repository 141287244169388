import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TopProductsComponent} from "./top-products.component";
import {TextModule} from "../text/text.module";
import {CardModule} from "../card/card.module";
import {ButtonModule} from "../button/button.module";
import {SecondaryButtonComponent} from "../button/secondary/secondary.button.component";
import {OrderDialogModule} from "../../modules/shop/components/order-dialog/order-dialog.module";


@NgModule({
    declarations: [TopProductsComponent],
    imports: [
        CommonModule,
        TextModule,
        CardModule,
        ButtonModule,
        SecondaryButtonComponent,
        OrderDialogModule
    ],
    exports: [
        TopProductsComponent
    ]
})
export class TopProductsModule {
}