import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatusPageComponent} from "./status-page.component";
import {TextModule} from "../text/text.module";
import {FooterModule} from "../footer/footer.module";
import {PrimaryButtonComponent} from "../button/primary/primary.button.component";


@NgModule({
    declarations: [StatusPageComponent],
    imports: [
        CommonModule,
        TextModule,
        FooterModule,
        PrimaryButtonComponent
    ],
    exports: [
        StatusPageComponent
    ]
})
export class StatusPageModule {
}