import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CartOverviewComponent} from "./cart-overview.component";
import {CardModule} from "../card/card.module";
import {AppRoutingModule} from "../../app-routing.module";
import {CartService} from "../../modules/cart/service/cart.service";
import {ImageModule} from "../image/image.module";
import {QuantitySelectorModule} from "../quantity-selector/quantity-selector.module";
import {TextModule} from "../text/text.module";
import {PrimaryButtonComponent} from "../button/primary/primary.button.component";
import {SecondaryButtonComponent} from "../button/secondary/secondary.button.component";


@NgModule({
    declarations: [CartOverviewComponent],
    exports: [
        CartOverviewComponent
    ],
    imports: [
        CommonModule,
        CardModule,
        AppRoutingModule,
        ImageModule,
        QuantitySelectorModule,
        TextModule,
        PrimaryButtonComponent,
        SecondaryButtonComponent,
    ],
    providers: [CartService]
})
export class CartOverviewModule {
}
