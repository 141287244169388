import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableComponent} from "./table.component";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";


@NgModule({
    declarations: [TableComponent],
    exports: [
        TableComponent
    ],
    imports: [
        CommonModule,
        NgxDatatableModule
    ]
})
export class TableModule {
}