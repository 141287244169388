import {Component, OnInit} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {AuthService} from '@auth0/auth0-angular';
import {AsyncPipe} from "@angular/common";
import {ButtonModule} from "../button/button.module";
import {initTE, Modal} from "tw-elements";
import {DropdownMenuModule} from "../dropdown/dropdown-menu.module";
import {ImageModule} from "../image/image.module";
import {QuantitySelectorModule} from "../quantity-selector/quantity-selector.module";
import {TextModule} from "../text/text.module";
import {ToastBarModule} from "../toast-bar/toast-bar.module";
import {IconTextModule} from "../icon-text/icon-text.module";
import {PrimaryButtonComponent} from "../button/primary/primary.button.component";
import {SpinnerModule} from "../spinner/spinner.module";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {goToUrl} from "../../services/util/util";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.css"],
    imports: [
        FormsModule,
        AsyncPipe,
        ButtonModule,
        DropdownMenuModule,
        ImageModule,
        QuantitySelectorModule,
        TextModule,
        ToastBarModule,
        IconTextModule,
        PrimaryButtonComponent,
        SpinnerModule
    ],
    standalone: true
})
export class LoginComponent implements OnInit {

    constructor(public auth: AuthService,
                protected readonly router: Router) {
    }

    ngOnInit(): void {
        initTE({Modal});
    }

    protected readonly environment = environment;
    protected readonly goToUrl = goToUrl;
}
