<div class="h-fit p-5 pt-24 lg:pt-40 overflow-auto">
    <div class="py-3 md:mt-10 lg:grid grid-cols-3 text-center lg:w-4/5 mx-auto font-medium md:space-x-5 space-y-2 md:space-y-0">
        @for (link of links; track link; let i = $index) {
            <app-secondary-button
                    @slideDown [value]="link.text"
                    (click)="setActiveTable(link.route)"></app-secondary-button>
        }
    </div>
    @if (auth.user$ | async; as user) {
        @if (user.email === environment.admin) {
            @switch (activeTable) {
                @case ("orders") {
                    <app-table [rows]="orders" [columns]="orderColumns"></app-table>
                    @if (totalPages !== 0) {
                        <app-pagination
                                [pageNumber]="pageNumber"
                                [totalPages]="totalPages"
                                (pageChange)="onPageChange($event)">
                        </app-pagination>
                    }
                }
                @case ("products") {
                    <app-table [rows]="products" [columns]="productColumns"></app-table>
                    @if (totalPages !== 0) {
                        <app-pagination
                                [pageNumber]="pageNumber"
                                [totalPages]="totalPages"
                                (pageChange)="onPageChange($event)">
                        </app-pagination>
                    }
                }
                @case ("customers") {
                    <app-table [rows]="customers" [columns]="customerColumns"></app-table>
                    @if (totalPages !== 0) {
                        <app-pagination
                                [pageNumber]="pageNumber"
                                [totalPages]="totalPages"
                                (pageChange)="onPageChange($event)">
                        </app-pagination>
                    }
                }
            }
        }
    } @else {
        <p class="pt-56">unauthorised</p>
    }
</div>