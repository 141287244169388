import {Component} from '@angular/core';

import {AsyncPipe} from "@angular/common";
import {ButtonModule} from "../../button/button.module";

@Component({
    selector: 'app-login-dialog',
    standalone: true,
    imports: [
        AsyncPipe,
        ButtonModule
    ],
    templateUrl: './login-dialog.component.html',
    styleUrl: './login-dialog.component.css'
})
export class LoginDialogComponent {

    // constructor(public auth: AuthService) {
    // }

    protected readonly document = document;
}
