import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CartComponent} from "./cart.component";
import {AppRoutingModule} from "../../app-routing.module";
import {TextModule} from "../../components/text/text.module";
import {CardModule} from "../../components/card/card.module";
import {ButtonModule} from "../../components/button/button.module";
import {TextInputModule} from "../../components/text-input/text-input.module";
import {CartService} from "./service/cart.service";
import {PrimaryButtonComponent} from "../../components/button/primary/primary.button.component";



@NgModule({
  declarations: [CartComponent],
    imports: [
        CommonModule,
        AppRoutingModule,
        TextModule,
        CardModule,
        ButtonModule,
        TextInputModule,
        PrimaryButtonComponent
    ],
  providers: [CartService]
})
export class CartModule { }
