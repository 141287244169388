import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LinkComponent} from "./link.component";
import {RouterLink, RouterModule} from "@angular/router";



@NgModule({
    declarations: [
        LinkComponent
    ],
    exports: [
        LinkComponent
    ],
  imports: [
    CommonModule,
    RouterLink,
    RouterModule
  ]
})
export class LinkModule { }
