<nav aria-label="Page navigation" class="pt-10 flex justify-center">
    <ul class="flex list-none">
        <li class="mr-1">
            <button (click)="previousPage()"
                    [ngClass]="pageNumber === 0 ? 'text-gray-400 hover:bg-neutral-50' : ''"
                    [disabled]="pageNumber === 0"
                    class="relative block rounded bg-transparent px-3 py-1.5 text-sm text-surface transition duration-300 hover:bg-neutral-100">
                Предходна
            </button>
        </li>
        <ng-container *ngFor="let page of getDisplayedPageNumbers(); let i = index;">
            <li class="mr-1">
                <button (click)="onPageChange(page)"
                        [ngClass]="{'bg-gray-300': page === pageNumber, 'bg-transparent': page !== pageNumber}"
                        class="relative block rounded px-3 py-1.5 text-sm text-surface transition duration-300 hover:bg-neutral-100 focus:bg-gray-300 focus:text-secondary-800 focus:outline-none active:bg-neutral-100 active:text-secondary-800">
                    {{ page + 1 }}
                </button>
            </li>
        </ng-container>
        <li>
            <button (click)="nextPage()"
                    [ngClass]="pageNumber === totalPages - 1 ? 'text-gray-400 hover:bg-neutral-50' : ''"
                    [disabled]="pageNumber === totalPages - 1"
                    class="relative block rounded bg-transparent px-3 py-1.5 text-sm text-surface transition duration-300 hover:bg-neutral-100">
                Следна
            </button>
        </li>
    </ul>
</nav>