import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RelatedProductsComponent} from "./related-products.component";
import {TextModule} from "../../../../components/text/text.module";
import {CardModule} from "../../../../components/card/card.module";


@NgModule({
    declarations: [RelatedProductsComponent],
    exports: [
        RelatedProductsComponent
    ],
    imports: [
        CommonModule,
        TextModule,
        CardModule
    ]
})
export class RelatedProductsModule {
}