import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconTextListComponent} from "./icon-text-list.component";
import {IconTextModule} from "../icon-text/icon-text.module";
import {TextModule} from "../text/text.module";


@NgModule({
    declarations: [IconTextListComponent],
    imports: [
        CommonModule,
        IconTextModule,
        TextModule
    ],
    exports: [
        IconTextListComponent
    ]
})
export class IconTextListModule {
}
