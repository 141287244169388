import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MusicPlayerService} from './service/music-player.service';
import {Playlist} from '../../models/playlist';
import {YouTubePlayer} from '@angular/youtube-player';

@Component({
    selector: 'app-music-player',
    templateUrl: './music-player.component.html',
    styleUrls: ['./music-player.component.css']
})
export class MusicPlayerComponent implements OnInit, AfterViewInit {

    @ViewChild('youtubePlayer', {static: false}) youtubePlayer: YouTubePlayer | undefined;

    playlist: Playlist | undefined;

    currentTrackIndex: number = 0;

    currentTrackId: string = '';

    constructor(
        private readonly musicPlayerService: MusicPlayerService
    ) {
    }

    ngOnInit(): void {
        this.getYoutubePlaylist();
    }

    ngAfterViewInit(): void {
        this.getYoutubePlaylist();
    }

    getYoutubePlaylist() {
        this.musicPlayerService.getYoutubePlaylist("PL3JWcAgBiu2XhKibSISMTR2MPG8oC19mO")
            .subscribe(data => {
                this.playlist = data;
                this.setRandomTrackIndex();
                this.updateCurrentTrack();
            });
    }

    setRandomTrackIndex() {
        if (this.playlist && this.playlist.items.length > 0) {
            this.currentTrackIndex = Math.floor(Math.random() * this.playlist.items.length);
        }
    }

    updateCurrentTrack() {
        if (this.playlist && this.playlist.items) {
            const track = this.playlist.items[this.currentTrackIndex];
            this.currentTrackId = track.snippet.resourceId.videoId;
            this.playCurrentTrack();
        }
    }

    playCurrentTrack() {
        if (this.youtubePlayer) {
            this.youtubePlayer.videoId = this.currentTrackId;
            setTimeout(() => {
                this.youtubePlayer?.playVideo();
            }, 2500);
        } else {
            console.error('YouTube Player instance not found or not ready');
        }
    }

    onPlayerStateChange(event: YT.OnStateChangeEvent) {
        if (event.data === window['YT'].PlayerState.ENDED) {
            this.nextTrack();
        }
    }

    previousTrack() {
        if (this.playlist) {
            if (this.currentTrackIndex > 0) {
                this.currentTrackIndex--;
            } else {
                this.currentTrackIndex = this.playlist.items.length - 1;
            }
            this.updateCurrentTrack();
        }
    }

    nextTrack() {
        if (this.playlist) {
            if (this.currentTrackIndex < this.playlist.items.length - 1) {
                this.currentTrackIndex++;
            } else {
                this.currentTrackIndex = 0;
            }
            this.updateCurrentTrack();
        }
    }
}