import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SearchOverviewComponent} from "./search-overview.component";
import {CardModule} from "../card/card.module";
import {TextInputModule} from "../text-input/text-input.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TextModule} from "../text/text.module";
import {PrimaryButtonComponent} from "../button/primary/primary.button.component";
import {OrderDialogModule} from "../../modules/shop/components/order-dialog/order-dialog.module";
import {ProductPageModule} from "../../modules/shop/components/product-page/product-page.module";

@NgModule({
  declarations: [SearchOverviewComponent],
  exports: [
    SearchOverviewComponent
  ],
    imports: [
        CommonModule,
        CardModule,
        TextInputModule,
        FormsModule,
        ReactiveFormsModule,
        TextModule,
        PrimaryButtonComponent,
        OrderDialogModule,
        ProductPageModule
    ]
})
export class SearchOverviewModule { }
