import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DropdownOption} from "./model/dropdown-option";
import {initTE, Select} from "tw-elements";
import {UntypedFormControl} from "@angular/forms";

@Component({
    selector: 'app-dropdown-menu',
    templateUrl: './dropdown-menu.component.html',
    styleUrls: ['./dropdown-menu.component.css']
})
export class DropdownMenuComponent implements OnInit {

    @Input()
    control!: UntypedFormControl;

    @Input()
    options: DropdownOption[] = [];

    @Input()
    placeholder: string = '';

    @Output() optionSelected = new EventEmitter<string>();

    ngOnInit(): void {
        initTE({Select}, {allowReinits: true});
    }

    onChange(event: Event): void {
        const selectedValue = (event.target as HTMLSelectElement).value;
        this.control.setValue(selectedValue);
        this.optionSelected.emit(selectedValue);
    }
}