import {Component, HostListener, OnInit} from '@angular/core';
import {Slide} from "../../models/slide";
import {Router} from "@angular/router";
import {Carousel, initTE,} from "tw-elements";
import {goToUrl} from "../../services/util/util";

@Component({
    selector: 'app-hero-section',
    templateUrl: './hero-section.component.html',
    styleUrls: ['./hero-section.component.css'],
})
export class HeroSectionComponent implements OnInit {

    isMobile: boolean = false;

    slides: Slide[] = [
        {
            bgUrl: "/assets/images/hero-section/maica-goce-delcev-cover.webp",
            buttonText: "КУПИ МАИЦА",
            ariaLabel: "Маица Заклетва",
            action: () => goToUrl(this.router, '/shop/t_shirts')
        },
        {
            bgUrl: "/assets/images/hero-section/zname-kutles.webp",
            buttonText: "КУПИ ЗНАМЕ",
            ariaLabel: "Знаме Кутлеш",
            action: () => goToUrl(this.router, '/shop/flags')
        },
        {
            bgUrl: "/assets/images/hero-section/patriotski-sisinja-cover.webp",
            buttonText: "КУПИ ШИШЕ",
            ariaLabel: "Маица Заклетва",
            action: () => goToUrl(this.router, '/shop/bottles')
        },
        {
            bgUrl: "/assets/images/hero-section/makedonsko-zname.webp",
            buttonText: "КУПИ ЗНАМЕ",
            ariaLabel: "Македонско знаме",
            action: () => goToUrl(this.router, '/shop/flags')
        },
        {
            bgUrl: "/assets/images/hero-section/bluzon-cerep-so-slem-cover.webp",
            buttonText: "КУПИ БЛУЗОН",
            ariaLabel: "Блузон со шлем и череп",
            action: () => goToUrl(this.router, '/shop/sweat_shirts')
        },
    ];

    constructor(
        private readonly router: Router
    ) {
    }

    ngOnInit() {
        initTE({Carousel});
        this.checkIfMobile();
    }

    @HostListener('window:resize')
    onResize() {
        this.checkIfMobile();
    }

    checkIfMobile() {
        const screenWidth = window.innerWidth;
        this.isMobile = screenWidth < 1280;
    }
}