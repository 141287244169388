import {Component} from '@angular/core';
import {ProductCategory} from "../../models/productCategory";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {environment} from "../../environments/environment";

@Component({
    selector: 'app-product-category-list',
    templateUrl: './product-category-list.component.html',
    styleUrls: ['./product-category-list.component.css'],
    animations: [
        trigger('slideInFromBottom', [
            state('hidden', style({
                transform: 'translateY(200%)',
                opacity: 0
            })),
            state('visible', style({
                transform: 'translateY(0)',
                opacity: 1
            })),
            transition('hidden <=> visible', animate('0.7s')),
        ])]
})
export class ProductCategoryListComponent {

    productCategories: ProductCategory[] = [
        {
            image: '/assets/images/categories/maica.webp',
            link: {
                text: 'МАИЦИ',
                route: environment.navigation.shop + '/t_shirts'
            }
        },
        {
            image: '/assets/images/categories/zname.webp',
            link: {
                text: 'ЗНАМИЊА',
                route: environment.navigation.shop + '/flags'
            }
        },
        {
            image: '/assets/images/categories/cokancinja-i-sise.webp',
            link: {
                text: 'ШИШИЊА',
                route: environment.navigation.shop + '/bottles'
            }
        },
        {
            image: '/assets/images/categories/jersey.webp',
            link: {
                text: 'ДРЕСОВИ',
                route: environment.navigation.shop + '/jerseys'
            }
        },
        {
            image: '/assets/images/categories/dukser.webp',
            link: {
                text: 'ДУКСЕРИ',
                route: environment.navigation.shop + '/hoodies'
            }
        },
        {
            image: '/assets/images/categories/bluzon.webp',
            link: {
                text: 'БЛУЗОНИ',
                route: environment.navigation.shop + '/sweat_shirts'
            }
        },
        {
            image: '',
            link: {
                text: 'ОСТАНАТО',
                route: environment.navigation.shop + '/other'
            }
        },
        {
            image: '',
            link: {
                text: 'ПОПУСТИ',
                route: environment.navigation.shop + '/on-discount'
            }
        }
    ]

}
