<div class="relative overflow-y-auto px-10 space-y-6 pt-24 lg:pt-44 md:w-1/2 pb-10 mx-auto sm:h-screen">
    @if (auth.user$ | async; as user) {
        @if (user.email === environment.admin) {
            <div class="pt-3">
                <app-primary-button [value]="'Админ панел'"
                                    (click)="goToUrl(router, '/admin-panel')"
                />
            </div>
        }
        <img class="w-32 rounded-lg shadow-lg" src="{{user.picture}}" alt="profile pic">
        <app-text class="pt-3" [value]="'Е-маил: ' + user.email"></app-text>
        <div>
            <app-text [value]="'Име'"></app-text>
            <app-text-input
                    [placeholder]="'Внеси име'"
                    [control]="nameControl"></app-text-input>

        </div>
        <div>
            <app-text [value]="'Презиме'"></app-text>
            <app-text-input
                    [placeholder]="'Внеси презиме'"
                    [control]="surnameControl"></app-text-input>
        </div>
        <div>
            <app-text [value]="'Телефонски број'"></app-text>
            <app-text-input [placeholder]="'Внеси телефонски број'"
                            [control]="phoneNumberControl"
                            [type]="'required'"></app-text-input>
        </div>
        <div>
            <app-text [value]="'Адреса'"></app-text>
            <app-text-input [placeholder]="'Внеси адреса'"
                            [control]="addressControl"
                            [type]="'required'"></app-text-input>
        </div>
        <div>
            <app-text [value]="'Град'"></app-text>
            <app-text-input [placeholder]="'Внеси град на живеење'"
                            [control]="cityControl"
                            [type]="'required'"></app-text-input>
        </div>
        <!--Modal footer-->
        <div class="flex flex-shrink-0 flex-wrap items-center justify-end gap-x-3">
            <app-secondary-button
                    [value]="'Одјави се'"
                    (click)="auth.logout({ logoutParams: { returnTo: document.location.origin } })"/>
            <app-primary-button [value]="'Ажурирај податоци'"
                                (click)="updateCustomer()"></app-primary-button>
        </div>
    } @else {
        <span
                class="w-32 rounded-lg shadow-lg inline-block min-h-[8em] flex-auto cursor-wait bg-current align-middle text-base text-neutral-700 opacity-50 dark:text-neutral-50"></span>
        <app-text class="pt-3" [value]="'Е-маил: '"></app-text>
        <div>
            <app-text [value]="'Име'"></app-text>
            <span class="rounded-lg inline-block min-h-[2.6em] w-full flex-auto cursor-wait bg-current align-middle text-base text-neutral-700 opacity-50 dark:text-neutral-50"></span>

        </div>
        <div>
            <app-text [value]="'Презиме'"></app-text>
            <span class="rounded-lg inline-block min-h-[2.6em] w-full flex-auto cursor-wait bg-current align-middle text-base text-neutral-700 opacity-50 dark:text-neutral-50"></span>
        </div>
        <div>
            <app-text [value]="'Телефонски број'"></app-text>
            <span class="rounded-lg inline-block min-h-[2.6em] w-full flex-auto cursor-wait bg-current align-middle text-base text-neutral-700 opacity-50 dark:text-neutral-50"></span>
        </div>
        <div>
            <app-text [value]="'Адреса'"></app-text>
            <span class="rounded-lg inline-block min-h-[2.6em] w-full flex-auto cursor-wait bg-current align-middle text-base text-neutral-700 opacity-50 dark:text-neutral-50"></span>
        </div>
        <div>
            <app-text [value]="'Град'"></app-text>
            <span class="rounded-lg inline-block min-h-[2.6em] w-full flex-auto cursor-wait bg-current align-middle text-base text-neutral-700 opacity-50 dark:text-neutral-50"></span>
        </div>
        <!--Modal footer-->
        <div class="flex flex-shrink-0 flex-wrap items-center justify-end gap-x-3">
            <app-secondary-button
                    [value]="'Одјави се'"
                    [isLoading]="isLoading"/>
            <app-primary-button [value]="'Ажурирај податоци'"
                                [isLoading]="isLoading"/>
        </div>
    }
    <app-toast [message]="toastMessage"></app-toast>
</div>
<app-footer></app-footer>