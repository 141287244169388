import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from "../../app-routing.module";
import {AboutUsComponent} from "./about-us.component";
import {TextModule} from "../../components/text/text.module";
import {RouterLink, RouterModule} from "@angular/router";
import {FooterModule} from "../../components/footer/footer.module";
import {PrimaryButtonComponent} from "../../components/button/primary/primary.button.component";
import {ButtonModule} from "../../components/button/button.module";
import {SecondaryButtonComponent} from "../../components/button/secondary/secondary.button.component";


@NgModule({
    declarations: [AboutUsComponent],
    imports: [
        CommonModule,
        AppRoutingModule,
        TextModule,
        RouterLink,
        RouterModule,
        FooterModule,
        PrimaryButtonComponent,
        ButtonModule,
        SecondaryButtonComponent
    ]
})
export class AboutUsModule {
}
