import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmailFormComponent} from "./email-form.component";
import {TextModule} from "../text/text.module";
import {TextInputModule} from "../text-input/text-input.module";
import {ButtonModule} from "../button/button.module";
import {EmailFormService} from "./service/email-form.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PrimaryButtonComponent} from "../button/primary/primary.button.component";


@NgModule({
    declarations: [EmailFormComponent],
    imports: [
        CommonModule,
        TextModule,
        TextInputModule,
        ButtonModule,
        ReactiveFormsModule,
        FormsModule,
        PrimaryButtonComponent
    ],
    exports: [
        EmailFormComponent
    ],
    providers: [EmailFormService]
})
export class EmailFormModule {
}