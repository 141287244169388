import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Product} from "../../../models/product";
import {getPageDefaults, getPageNumberAndSize, Page} from "../../../models/paginator";
import {environment} from "../../../environments/environment";
import {getSearchedEntity} from "../../../services/util/util";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Search} from "../../../models/filters";


@Injectable()
export class ShopService {

    private productsPageSubject$: BehaviorSubject<Page<Product>> = new BehaviorSubject<Page<Product>>(getPageDefaults());

    constructor(
        private readonly httpClient: HttpClient) {
    }

    get productsPage$(): Observable<Page<Product>> {
        return this.productsPageSubject$.asObservable();
    }

    getAllProducts(pageNumber: number, pageSize: number): void {
        const url: string = environment.domain.backend + environment.api.products + "/paged";

        const params = new HttpParams()
            .set('page', pageNumber.toString())
            .set('size', pageSize.toString())
            .set('sort', "created,asc");

        this.httpClient.get<Page<Product>>(url, {params}).subscribe((page) => {
            this.productsPageSubject$.next(page);
        });
    }

    getProductById(id: number): Observable<Product> {
        const url: string =
            environment.domain.backend +
            environment.api.products +
            "/" + id;

        return this.httpClient.get<Product>(url);
    }

    getProductsByCategory(category: string): Observable<Product[]> {
        const url: string =
            environment.domain.backend +
            environment.api.products +
            "/category?category=" + category.toUpperCase();

        return this.httpClient.get<Product[]>(url);
    }

    getTopSellingProducts(limit: number): Observable<Product[]> {
        const url: string =
            environment.domain.backend +
            environment.api.products +
            "/top-selling?limit=" + limit;

        return this.httpClient.get<Product[]>(url);
    }

    getSearchedProducts(search: Search, pageNumber?: number, pageSize?: number): void {
        const url: string =
            environment.domain.backend +
            environment.api.products +
            "/search" +
            getPageNumberAndSize(pageNumber, pageSize);

        getSearchedEntity<Product>(search, this.productsPageSubject$, this.httpClient, url);
    }

    getDiscountedProducts(): Observable<Product[]> {
        const url: string =
            environment.domain.backend +
            environment.api.products +
            "/on-discount";

        return this.httpClient.get<Product[]>(url);
    }

    updateProduct(product: Product): Observable<Product> {
        const url: string = environment.domain.backend + environment.api.products + "/" + product.id;

        return this.httpClient.put<Product>(url, product);
    }

    deleteProduct(productId: number): Observable<void> {
        const url: string = environment.domain.backend + environment.api.products + "/" + productId;

        return this.httpClient.delete<void>(url);
    }
}