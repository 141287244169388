<div class="p-0 lg:p-16 pt-24 lg:pt-40">
    <div class="grid lg:grid-cols-2 relative mb-5 md:mb-0">
        <div class="pt-3 lg:pt-8 px-10 relative">
            <img class="w-56 rounded-lg shadow-lg"
                 [src]="'assets/images/' + product?.category?.toLowerCase()?.replace('_', '-') + '/' + (product?.images?.[0] ?? '') + '.webp'"
                 alt="product pic">
            <div>

                <app-text [value]="'Име'"></app-text>
                <app-text-input
                        [placeholder]="'Внеси име'"
                        [control]="nameControl"></app-text-input>

            </div>
            <div>
                <app-text [value]="'Категорија'"></app-text>
                <app-text-input
                        [placeholder]="'Внеси категорија'"
                        [control]="categoryControl"></app-text-input>
            </div>
            <div>
                <app-text [value]="'Дизајн'"></app-text>
                <app-text-input [placeholder]="'Внеси дизајн'"
                                [control]="designIDControl"
                                [type]="'required'"></app-text-input>
            </div>
            <div>
                <app-text [value]="'Опис'"></app-text>
                <app-text-input [placeholder]="'Внеси опис'"
                                [control]="descriptionControl"
                                [type]="'required'"></app-text-input>
            </div>
            <div>
                <app-text [value]="'Слаг'"></app-text>
                <app-text-input [placeholder]="'Внеси слаг'"
                                [control]="slugControl"
                                [type]="'required'"></app-text-input>
            </div>
            <div>
                <app-text [value]="'Клучни зборови'"></app-text>
                <app-text-input [placeholder]="'Внеси клучни зборови'"
                                [control]="keywordsControl"
                                [type]="'required'"></app-text-input>
            </div>
        </div>
        <form [formGroup]="productControl" class="pt-3 lg:pt-8 px-10 relative">
            <div>
                <app-text [value]="'Подкатегорија'"></app-text>
                <app-text-input [placeholder]="'Внеси подкатегорија'"
                                [control]="subCategoryControl"
                                [type]="'required'"></app-text-input>
            </div>
            <div>
                <app-text [value]="'Слики'"></app-text>
                <app-text-input [placeholder]="'Внеси слики'"
                                [control]="imagesControl"
                                [type]="'required'"></app-text-input>
            </div>
            <div>
                <app-text [value]="'Достапни величини'"></app-text>
                <app-text-input [placeholder]="'Внеси достапни величини'"
                                [control]="availableSizesControl"
                                [type]="'required'"></app-text-input>
            </div>
            <div>
                <app-text [value]="'Достапни бои'"></app-text>
                <app-text-input [placeholder]="'Внеси достапни бои'"
                                [control]="availableColorsControl"
                                [type]="'required'"></app-text-input>
            </div>
            <div>
                <app-text [value]="'Оргинална цена'"></app-text>
                <app-text-input [placeholder]="'Внеси оргинална цена'"
                                [control]="originalPriceControl"
                                [type]="'required'"></app-text-input>
            </div>
            <div>
                <app-text [value]="'Цена'"></app-text>
                <app-text-input [placeholder]="'Внеси цена'"
                                [control]="pricePriceControl"
                                [type]="'required'"></app-text-input>
            </div>
            <div>
                <app-text [value]="'Датум на креирање'"></app-text>
                <app-text-input [placeholder]="'Внеси датум на креирање'"
                                [control]="createdControl"
                                [type]="'required'"></app-text-input>
            </div>
            <div>
                <app-text [value]="'Попуст'"></app-text>
                <app-text-input [placeholder]="'Внеси попуст'"
                                [control]="discountControl"
                                [type]="'required'"></app-text-input>
            </div>

        </form>
    </div>
    <div class="flex flex-shrink-0 flex-wrap items-center justify-center gap-x-3 p-5">
        <app-secondary-button
                class="bg-red-600"
                [value]="'Избриши го продуктот'"
                (click)="deleteProduct()"/>
        <app-primary-button [value]="'Промени ги податоците'"
                            (click)="updateProduct()"></app-primary-button>
    </div>
</div>