import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {getPageDefaults, getPageNumberAndSize, Page} from "../../../models/paginator";
import {Customer} from "../../../models/customer";
import {environment} from "../../../environments/environment";
import {getEntityPage} from "../../../services/util/util";
import {Order} from "../../../models/order";

@Injectable()
export class AdminPanelService {

    private customersPageSubject$: BehaviorSubject<Page<Customer>> = new BehaviorSubject<Page<Customer>>(getPageDefaults());

    private ordersPageSubject$: BehaviorSubject<Page<Order>> = new BehaviorSubject<Page<Order>>(getPageDefaults());

    constructor(
        private readonly httpClient: HttpClient) {
    }

    get customersPage$(): Observable<Page<Customer>> {
        return this.customersPageSubject$.asObservable();
    }

    get ordersPage$(): Observable<Page<Order>> {
        return this.ordersPageSubject$.asObservable();
    }

    getAllCustomers(pageNumber?: number, pageSize?: number): void {
        const url: string =
            environment.domain.backend +
            environment.api.customers +
            getPageNumberAndSize(pageNumber, pageSize);

        getEntityPage<Customer>(this.customersPageSubject$, this.httpClient, url);
    }

    getPaginatedOrders(pageNumber: number, pageSize: number): void {
        const url: string = environment.domain.backend + environment.api.orders;

        const params = new HttpParams()
            .set('page', pageNumber.toString())
            .set('size', pageSize.toString())
            .set('sort', "created,asc");

        this.httpClient.get<Page<Order>>(url, {params}).subscribe((page) => {
            this.ordersPageSubject$.next(page);
        });
    }

}