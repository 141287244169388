import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductManageComponent} from "./product-manage.component";
import {TextInputModule} from "../../../../components/text-input/text-input.module";
import {TextModule} from "../../../../components/text/text.module";
import {ReactiveFormsModule} from "@angular/forms";
import {PrimaryButtonComponent} from "../../../../components/button/primary/primary.button.component";
import {SecondaryButtonComponent} from "../../../../components/button/secondary/secondary.button.component";


@NgModule({
    declarations: [ProductManageComponent],
    exports: [
        ProductManageComponent
    ],
    imports: [
        CommonModule,
        TextInputModule,
        TextModule,
        ReactiveFormsModule,
        PrimaryButtonComponent,
        SecondaryButtonComponent
    ],
})
export class ProductManageModule {
}