import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {initTE, Modal, Ripple} from "tw-elements";


@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrl: './confirmation-modal.component.css'
})
export class ConfirmationModalComponent implements OnInit {

    @Output() confirmationEvent = new EventEmitter();

    ngOnInit(): void {
        initTE({Modal, Ripple});
    }

}
