import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MusicPlayerComponent} from "./music-player.component";
import {MusicPlayerService} from "./service/music-player.service";
import {SafePipe} from "../../services/util/safe.pipe";
import {PrimaryButtonComponent} from "../button/primary/primary.button.component";
import {SecondaryButtonComponent} from "../button/secondary/secondary.button.component";
import {YouTubePlayerModule} from "@angular/youtube-player";


@NgModule({
    declarations: [MusicPlayerComponent],
    exports: [MusicPlayerComponent],
    imports: [
        CommonModule,
        SafePipe,
        PrimaryButtonComponent,
        SecondaryButtonComponent,
        YouTubePlayerModule
    ],
    providers: [MusicPlayerService]
})
export class MusicPlayerModule {
}