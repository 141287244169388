<button data-te-ripple-init
        data-te-ripple-color="dark"
        [disabled]="disabled || isLoading"
        class="w-full rounded border-2 border-black pb-[6px] p-2 text-sm font-medium leading-normal text-black transition duration-150 ease-in-out hover:border-gray-800 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-gray-600 focus:border-gray-600 focus:text-gray-600 focus:outline-none focus:ring-0 active:border-gray-700 active:text-gray-700 focus:bg-gray-300"
        data-te-toggle="tooltip"
        data-te-placement="top"
        title="{{tooltip}}">
    @if (icon) {
        <app-svg-icon class="w-16" [url]="icon"></app-svg-icon>
    } @else if (isLoading) {
        <app-spinner></app-spinner>
    } @else {
        {{ value }}
    }
</button>