import {Component, HostListener, Input, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {EmailFormService} from "./service/email-form.service";
import {initTE} from "tw-elements";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-email-form',
    templateUrl: './email-form.component.html',
    styleUrls: ['./email-form.component.css'],
    animations: [
        trigger('slideInFromBottom', [
            state('hidden', style({
                transform: 'translateY(200%)',
                opacity: 0
            })),
            state('visible', style({
                transform: 'translateY(0)',
                opacity: 1
            })),
            transition('hidden <=> visible', animate('0.7s')),
        ])]
})
export class EmailFormComponent implements OnInit {

    emailFormControl = new FormControl('', [Validators.email]);

    infoMessage: string = '';

    showAnimation: boolean = false;

    isLoading: boolean = false;

    showInfoMessage: boolean = false;

    private timer: ReturnType<typeof setTimeout> | undefined;

    constructor(
        private readonly emailFormService: EmailFormService) {
    }

    ngOnInit(): void {
        initTE({Input});
    }

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const componentElement = document.getElementById('emailFormComponent');
        if (componentElement) {
            const rect = componentElement.getBoundingClientRect();
            this.showAnimation = rect.top <= window.innerHeight && rect.bottom >= 0;
        }
    }

    submitEmail() {
        if (this.emailFormControl.status === "VALID" && this.emailFormControl.value != "") {
            this.isLoading = true;
            this.emailFormService.createCustomerWithEmail(this.emailFormControl.value!).subscribe({
                next: () => {
                    this.triggerInfoMessage("Успешно пратена порака.")
                },
                error: () => {
                    this.triggerInfoMessage("Грешка при праќање на пораката.")
                },
                complete: () => this.isLoading = false
            });
        } else {
            if (this.emailFormControl.hasError('email')) {
                this.triggerInfoMessage("Невалидна е-маил адреса")
            } else {
                this.triggerInfoMessage("Пополнете ја формата")
            }
        }
    }

    triggerInfoMessage(message: string): void {
        this.infoMessage = message;
        this.showInfoMessage = true;

        this.timer = window.setTimeout(() => {
            this.showInfoMessage = false;

            if (this.timer) {
                window.clearTimeout(this.timer);
                this.timer = undefined;
            }
        }, 5000);
    }

    closeInfoMessage() {
        this.showInfoMessage = false;
    }
}
