<!--TODO make the animation @slideInOut work-->
<div class="z-30 fixed bottom-5 right-0 left-0 pointer-events-auto mx-auto hidden w-96 max-w-full rounded-lg bg-white bg-clip-padding text-sm shadow-lg shadow-black/5 data-[te-toast-show]:block data-[te-toast-hide]:hidden dark:bg-neutral-600"
     id="toast"
     role="alert"
     aria-live="assertive"
     aria-atomic="true"
     data-te-autohide="true"
     data-te-toast-init>
    <div class="flex items-center justify-between rounded-lg border-b-2 border-neutral-100 border-opacity-100 bg-white bg-clip-padding px-4 pb-2 pt-2.5 dark:border-opacity-50 dark:bg-neutral-600">
        <p class="font-bold text-neutral-500 dark:text-neutral-200">
            {{ message }}
        </p>
        <div class="flex items-center">
            <button type="button"
                    class="ml-2 box-content rounded-none border-none opacity-80 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                    aria-label="Close"
                    data-te-toast-dismiss>
        <span class="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
          <svg xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               stroke-width="1.5"
               stroke="currentColor"
               class="h-6 w-6">
            <path stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </span>
            </button>
        </div>
    </div>
</div>