import {NgModule} from '@angular/core';
import {AsyncPipe, CommonModule} from '@angular/common';
import {ProductPageComponent} from "./product-page.component";
import {DropdownMenuModule} from "../../../../components/dropdown/dropdown-menu.module";
import {ImageModule} from "../../../../components/image/image.module";
import {PrimaryButtonComponent} from "../../../../components/button/primary/primary.button.component";
import {QuantitySelectorModule} from "../../../../components/quantity-selector/quantity-selector.module";
import {ReactiveFormsModule} from "@angular/forms";
import {SecondaryButtonComponent} from "../../../../components/button/secondary/secondary.button.component";
import {TextModule} from "../../../../components/text/text.module";
import {ToastBarModule} from "../../../../components/toast-bar/toast-bar.module";
import {LinkModule} from "../../../../components/link/link.module";
import {FooterModule} from "../../../../components/footer/footer.module";
import {RelatedProductsModule} from "../related-products/related-products.module";
import {CardModule} from "../../../../components/card/card.module";


@NgModule({
    declarations: [ProductPageComponent],
    exports: [
        ProductPageComponent
    ],
    imports: [
        CommonModule,
        AsyncPipe,
        DropdownMenuModule,
        ImageModule,
        PrimaryButtonComponent,
        QuantitySelectorModule,
        ReactiveFormsModule,
        SecondaryButtonComponent,
        TextModule,
        ToastBarModule,
        LinkModule,
        FooterModule,
        RelatedProductsModule,
        CardModule
    ]
})
export class ProductPageModule {
}
