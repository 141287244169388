import {Component, Input, OnChanges} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
    selector: 'app-svg-icon',
    templateUrl: `./svg-icon.component.html`,
    styleUrls: ['./svg-icon.component.css'],
})
export class SvgIconComponent implements OnChanges {

    @Input()
    public url?: string;

    public svgIcon?: SafeHtml | null;

    isLoading: boolean = true;

    constructor(
        private httpClient: HttpClient,
        private sanitizer: DomSanitizer,
    ) {
    }

    public ngOnChanges(): void {
        if (!this.url) {
            this.svgIcon = null;
            return;
        }
        this.svgIcon = this.httpClient
            .get(`${this.url}`, {responseType: 'text'})
            .subscribe(value => {
                    this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
                },
                (error) => {
                    console.error('Error loading SVG file:', error);
                    // Handle error (e.g., show error message)
                },
                () => {
                    this.isLoading = false;
                });
    }
}