<div class="lg:overflow-x-scroll overflow-hidden">
    <div class="w-fit 2xl:grid-cols-7 grid lg:flex lg:flex-row py-14 md:py-32 gap-8 px-10 mx-auto items-center justify-center">
        @for (category of productCategories; track category) {
            <a [routerLink]="[category.link.route]" routerLinkActive="active"
               class="relative hover:underline mx-auto block max-w-[18rem] rounded-lg border-2 transform hover:scale-110 transition-transform shadow-lg pb-2 min-w-[18rem]">
                <div class="bg-auto bg-no-repeat bg-center screen-image absolute rounded-lg top-0 right-0 left-0 bottom-0 opacity-60"
                     [style.background-image]="'url(/assets/images/alexander-the-great.jpg)'"></div>
                <div class="relative">
                    <app-image class="h-72 p-1 rounded-t-lg object-cover" [src]="category.image"></app-image>
                </div>
                <div class="relative pb-3 text-center text-xl font-medium text-black">
                    <app-text [value]="category.link.text"></app-text>
                </div>
            </a>
        }
    </div>
</div>